<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">List Orders</div>
      </md-card-header>
      <md-card-content>
        <BaseOrderTable :tableData="orderCustomer"></BaseOrderTable>
      </md-card-content>
      <md-card-actions>
        <base-pagination :data="orderCustomer" :totalPage="totalPage" @onUpdate="getAllOrder"></base-pagination>
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>
import {getOrderByCustomer} from '@/services/customers';
import BasePagination from "@/components/BasePagination";
import BaseOrderTable from "@/components/Orders/BaseOrderTable";

export default {
  components: {BasePagination, BaseOrderTable},
  data: () => ({
    orderCustomer: [],
    totalPage: null
  }),
  methods: {
    getAllOrder(pageNumber) {
      getOrderByCustomer(this.$route.params.id, pageNumber).then(res => {
        if (res.data.lists.length > 0) {
          this.orderCustomer = res.data.lists;
          this.totalPage = res.data.totalPages;
        } else {
          console.log('Not list orders');
        }
      });
    },
  },
  created() {
    this.getAllOrder(1);
  },
}
</script>
<style>
</style>
