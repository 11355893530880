<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">App Errors</div>
      </md-card-header>
      <md-card-content>

        <md-table v-model="searched" md-card >
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable"/>
            </md-field>
          </md-table-toolbar>

          <md-table-empty-state
            md-label="No Errors found"
            :md-description="`No Errors found for this '${search}' query. Try a different search term.`">
          </md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="ID" md-sort-by="id">{{ item.id }}</md-table-cell>
            <md-table-cell md-label="UserId" md-sort-by="UserId">{{ item.user.name }}</md-table-cell>
            <md-table-cell md-label="OS" md-sort-by="OS">{{ item.os }}</md-table-cell>
            <md-table-cell md-label="Message" md-sort-by="msg">{{ item.msg }}</md-table-cell>
            <md-table-cell md-label="Location" md-sort-by="location">{{ item.location }}</md-table-cell>
            <md-table-cell md-label="Files" md-sort-by="files">{{ item.files }}</md-table-cell>
            <md-table-cell md-label="Creado" md-sort-by="Creado">{{ item.createdAt }}</md-table-cell>
            <md-table-cell md-label="Es cliente?">
              <md-checkbox v-model="item.isClient" class="md-primary" readonly="true" disabled></md-checkbox>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions>
        <base-pagination :data="searched" :totalPage="totalPage" @onUpdate="getPageErrors"></base-pagination>
      </md-card-actions>
    </md-card>
  </div>
</template>

<style scoped>
.md-table{
  overflow: auto;
  height: 30vw;
}
.md-table-head{
  position: sticky;
  top: 0;
  z-index: 1;
  overflow: unset;
  background: white;
}
</style>
<script>

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    let values = items.filter(x => !!x.name);
    return values.filter(item => toLower(item.name).includes(toLower(term)))
  }
  return items
}

import BaseTable from "@/components/BaseTable";
import BasePagination from "@/components/BasePagination";
import { getAppErrors } from '@/services/appError';


export default {
  components: {BaseTable, BasePagination},
  data() {
    return {
      tableData: [],
      totalPage: null,
      search: null,
      searched: [],
    }
  },
  methods: {
    getPageErrors(pageNumber) {
      getAppErrors(pageNumber).then(res => {
        if (res.data.lists.length > 0) {
          this.tableData = res.data.lists;
          this.totalPage = res.data.totalPages;
          this.searched = this.tableData;
        } else {
          console.log('Not App Errors List');
        }
      });
    },
    searchOnTable() {
      this.searched = searchByName(this.tableData, this.search)
    },
  },
  mounted() {
    this.getPageErrors(1);

  }
}
</script>
