import {getHeaders, getHeadersImg, handleLogout} from "@/services/auth";

const axios = require('axios');

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}broomer`;
const APIGetUser = `${process.env.VUE_APP_BROOM_API_ENDPOINT}getUserByRol`;
const APIFileUpload = `${process.env.VUE_APP_BROOM_API_ENDPOINT}broomer/uploadImage`;

export const getListBroomer = async (pageNumber) => {
  try {
    return await axios.get(API + '/' + pageNumber, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
};

export const postBroomer = async (data) => {
  try {
    return await axios.post(API, data, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const putBroomer = async (data) => {
  try {
    return await axios.put(API, data, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const deleteBroomer = async (id) => {
  try {
    const APIDelete = API + '/' + id;
    return await axios.delete(APIDelete, {
      headers: getHeaders()
    });
  } catch (e) {
    console.log()
  }
}

export const getUserByRol = async () => {
  try {
    return await axios.get(APIGetUser, {
      headers: getHeaders(),
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const fileUpload = async (data) => {
  try {
    return await axios.post(APIFileUpload, data, {
      headers: getHeadersImg(),
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const getBroomerById = async (id) => {
  try {
    const APIGetBroomer = API + '/getById/' + id;
    return await axios.get(APIGetBroomer, {
      headers: getHeaders(),
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const getOrdersByBroomer = async (id, pageNumber) => {
  try {
    const APIgetOrdersByBroomer = API + '/getOrdersByBroomer/' + id + '/' + pageNumber;
    return await axios.get(APIgetOrdersByBroomer, {
      headers: getHeaders(),
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const getListBroom = async (id) => {
  try {
    const APIList = `${process.env.VUE_APP_BROOM_API_ENDPOINT}orders`
    return await axios.get(APIList + '/listBroom/' + id, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
};

export const getFinishedServices = async (data) => {
  try {
    const GetList = `${process.env.VUE_APP_BROOM_API_ENDPOINT}getFinishedServices`;
    return await axios.post(GetList, data, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const putDataServices = async (data) => {
  try {
    const putList = `${process.env.VUE_APP_BROOM_API_ENDPOINT}putFinishedServices`;
    return await axios.put(putList, data, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}
