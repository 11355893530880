import {getHeaders, handleLogout} from "@/services/auth";

const axios = require('axios');

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}log`;

export const saveLog = async (data) => {
  try {
    return await axios.post(API, data, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}
