<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Order Broomers</div>
        <button class="btn btn-success" v-on:click="payBroomer" :disabled="!ordersBroomer.length">Pay finish orders</button>
      </md-card-header>
      <md-card-content>
        <BaseOrderTable :tableData="ordersBroomer"></BaseOrderTable>
      </md-card-content>
      <md-card-actions>
        <base-pagination :data="ordersBroomer" :totalPage="totalPage" @onUpdate="getAllOrders"></base-pagination>
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>
import {getOrdersByBroomer} from '@/services/broomers';
import BasePagination from "@/components/BasePagination";
import BaseOrderTable from "@/components/Orders/BaseOrderTable";

export default {
  components: {BasePagination, BaseOrderTable},
  data: () => ({
    ordersBroomer: [],
    totalPage: null
  }),
  methods: {
    getAllOrders(pageNumber) {
      getOrdersByBroomer(this.$route.params.id, pageNumber).then(res => {
        if (res.data.lists.length > 0) {
          this.ordersBroomer = res.data.lists;
          this.totalPage = res.data.totalPages;
        } else {
          console.log('Not list orders');
        }
      });
    },
    payBroomer() {
      this.$router.push('/payBroomer/' + this.$route.params.id);
    },
  },
  created() {
    this.getAllOrders(1);
  },
}
</script>
<style>
</style>
