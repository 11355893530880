<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">New Promotion</div>
      </md-card-header>
      <md-card-content>
        <form v-on:submit.prevent="createPromotion">
          <md-list class="md-double-line">
            <md-toolbar md-elevation="0">
              <md-subheader class="md-title">Information</md-subheader>
            </md-toolbar>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="name">Description</label>
                  <md-input id="name" type="text" v-model="promotion.description" required/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="email">Type</label>
                  <md-select v-model="promotion.type" name="type" id="type" required>
                    <md-option value="percentage">Percentage (%)</md-option>
                    <md-option value="value">Fixed Value (USD)</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="phone">Value</label>
                  <md-input id="value" type="number" v-model="promotion.value" required/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="maxValue">Max Discount</label>
                  <md-input id="maxValue" type="maxValue" v-model="promotion.maxDiscount"/>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-datepicker id="birthDate" v-model="promotion.startDate" >
                  <label>Promotion Start Date</label>
                </md-datepicker>
              </div>
              <div class="col-sm">
                <md-datepicker id="birthDate" v-model="promotion.endDate" >
                  <label>Promotion End Date</label>
                </md-datepicker>
              </div>
            </div>

            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="client">Clients</label>
                  <md-select v-model="promotion.clientsSelected" @md-selected="disableIsPublic" name="client" id="client" multiple>
                    <md-option :value="item.id" v-for="item in client"> {{ item.name }} -</md-option>
                  </md-select>
                </md-field>
              </div>
              <div class="col-sm">
                <md-checkbox
                  class="md-primary"
                  v-model="promotion.isPublic"
                  @change="emptyClients">Is Public</md-checkbox>
              </div>
            </div>

          </md-list>
          <md-divider></md-divider>
          <div class="col-sm" role="group" aria-label="">
            <button type="submit" class="btn btn-success">Add</button>
            <button class="btn btn-warning" v-on:click="canceled">Canceled</button>
          </div>
        </form>
      </md-card-content>
    </md-card>
    <base-loading :isLoading="isLoading"></base-loading>
  </div>
</template>

<script>
import { postPromotion } from "@/services/promotion";
import { getListCustomer } from "@/services/customers";
import BaseLoading from "@/components/BaseLoading";

export default {
  data() {
    return {
      client:null,
      promotion:{
        clientsSelected:[],
        maxDiscount:0,
        isPublic: false,
      },
      isLoading: false
    }
  },
  methods: {
    disableIsPublic(){
      if(this.promotion.clientsSelected.length > 0)
        this.promotion.isPublic = false;
    },
    emptyClients(){
      if(this.promotion.isPublic)
        this.promotion.clientsSelected = [];
    },
    getCustomers(){
      this.isLoading = true;
      getListCustomer().then(res => {
        if (res.data.length > 0) {
          this.client = res.data;
        }
      }).catch(console.log);
      this.isLoading = false;
    },
    createPromotion() {
      this.isLoading = true;
      let dataSend = {
        description: this.promotion.description,
        type: this.promotion.type,
        value: this.promotion.value,
        maxDiscount: (this.promotion.maxDiscount) ? this.promotion.maxDiscount : 0,
        startDate:this.promotion.startDate,
        endDate:this.promotion.endDate,
        isPublic:this.promotion.isPublic,
        clientsSelected: this.promotion.clientsSelected
      };
      if (dataSend) {
        postPromotion(dataSend).then(res => {
          if (res.data === true) {
            this.$router.push('/promotion');
          } else {
            console.log('Not Create Promotion');
          }
          this.isLoading = false;
        });
      }
    },
    canceled() {
      this.$router.push('/promotion');
    }
  },
  mounted() {
    this.getCustomers();
  },
  components: {
    BaseLoading
  }
}
</script>
