<template>
  <div>
    <md-table v-model="tableData" class="table-striped" md-sort="status" md-sort-order="asc">

      <md-table-empty-state
        md-label="No data found"
        :md-description="`No data found for this id.`">
      </md-table-empty-state>

      <md-table-row slot="md-table-row" slot-scope="{ item }" :class="item.inEmergency ? 'emergency' : ''">
        <md-table-cell md-label="Price">{{ item.price }}</md-table-cell>
        <md-table-cell md-label="Nº Users">{{ item.numberOfUsers }}</md-table-cell>
        <md-table-cell md-label="Distance">{{ item.distance / 1000 }} Km</md-table-cell>
        <md-table-cell md-label="Duration">{{ item.duration }}</md-table-cell>
        <md-table-cell md-label="Payment">{{ item.paymentMethod.name }}</md-table-cell>
        <md-table-cell md-label="Service">{{ item.service.name }}</md-table-cell>
        <md-table-cell md-label="Status">
          <div class="contenedor">
            <md-chip v-bind:class="getStatus(item.status)" class="text-neutral text-capitalize btn-block text-center">
              {{ item.status }}
            </md-chip>
            <base-button type="warning" size="sm" icon v-on:click="editStatus(item.id)"
                         v-if="item.status !== 'closed' && item.status !== 'finished'">
              <i class="tim-icons icon-pencil"></i>
            </base-button>
          </div>
        </md-table-cell>
        <md-table-cell md-label="Start Address">{{ item.startAddress }}</md-table-cell>
        <md-table-cell md-label="End Address">{{ item.endAddress }}</md-table-cell>
        <md-table-cell md-label="Date">{{ formatDate(item.createdAt) }}</md-table-cell>
        <md-table-cell md-label="Time">{{ formatTime(item.createdAt) }}</md-table-cell>
        <md-table-cell md-label="In Emergency">{{ item.inEmergency }}</md-table-cell>
        <md-table-cell md-label="Actions">
          <base-button type="success" size="sm" icon v-on:click="showOrder(item.id)">
            <i class="tim-icons icon-settings"></i>
          </base-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<style>
.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
}
.emergency{
  background-color: #dc3545 !important;
  color: white;
}
</style>
<script>
import BaseTable from "@/components/BaseTable";

export default {
  name: 'base-order-table',
  props: {
    tableData: {
      type: Array,
      default: () => [],
      description: "Table columns"
    },
  },
  methods: {
    formatDate(date) {
      const options = {year: 'numeric', month: 'long', day: 'numeric'}
      return new Date(date).toLocaleDateString('en', options);
    },
    formatTime(date) {
      const options = {hour12: false}
      return new Date(date).toLocaleTimeString('en', options);
    },
    getStatus(status) {
      switch (status) {
        case 'finished':
          return 'btn-success';
        case 'closed':
          return 'btn-danger';
        case 'requested':
          return 'btn-info';
        default:
          return 'btn-secondary';
      }
    },
    showOrder(id){
      this.$router.push('/showOrders/' + id);
    },
    editStatus(id, status) {
      this.$router.push('/updateOrder/' + id);
    },
  },
  components: {BaseTable},
}
</script>
