<template>
  <nav>
    <ul class="pagination pagination-sm flex-wrap">
      <li class="page-item">
        <a class="page-link" aria-label="Previous" v-on:click="prevPage">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>
      <li class="page-item" v-for="page in totalPage" v-bind:class="isActive(page)" v-on:click="isChangePage(page)">
        <a class="page-link">{{page }}</a></li>
      <li class="page-item">
        <a class="page-link" aria-label="Next" v-on:click="nextPage">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: "base-pagination",
  data: () => ({
    currentPage: 1
  }),
  props: {
    data: {
      type: Array,
      default: () => [],
      description: "Data"
    },
    totalPage: {
      type: Number,
      default: 1,
      description: "Number pages"
    }
  },
  methods: {
    nextPage() {
      if (this.currentPage < this.totalPage) {
        this.currentPage++;
        this.$emit('onUpdate', this.currentPage);
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.$emit('onUpdate', this.currentPage);
      }
    },
    isActive(noPage) {
      if (noPage === this.currentPage) {
        return 'active';
      } else {
        return '';
      }
    },
    isChangePage(noPage) {
      this.currentPage = noPage;
      this.$emit('onUpdate', noPage);
      this.isActive(noPage);
    }
  }
}
</script>
