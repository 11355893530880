<template>
  <div>

    <div class="row">
      <div class="col-12">
        <card type="chart">
          <template slot="header">
            <div class="row">
              <div class="col-sm-6" :class="isRTL ? 'text-right' : 'text-left'">
                <h5 class="card-category">{{ $t('dashboard.totalShipments') }}</h5>
                <h2 class="card-title">{{ $t('dashboard.performance') }}</h2>
              </div>
              <div class="col-sm-6">
                <div class="btn-group btn-group-toggle"
                     :class="isRTL ? 'float-left' : 'float-right'"
                     data-toggle="buttons">
                  <label v-for="(option, index) in bigLineChartCategories"
                         :key="option"
                         class="btn btn-sm btn-primary btn-simple"
                         :class="{active: bigLineChart.activeIndex === index}"
                         :id="index">
                    <input type="radio"
                           @click="initBigChart(index)"
                           name="options" autocomplete="off"
                           :checked="bigLineChart.activeIndex === index">
                    {{ option }}
                  </label>
                </div>
              </div>
            </div>
          </template>

          <div class="chart-area">
            <line-chart style="height: 100%"
                        ref="bigChart"
                        chart-id="big-line-chart"
                        :chart-data="bigLineChart.chartData"
                        :gradient-colors="bigLineChart.gradientColors"
                        :gradient-stops="bigLineChart.gradientStops"
                        :extra-options="bigLineChart.extraOptions">
            </line-chart>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from '@/components/Charts/LineChart';
import * as chartConfigs from '@/components/Charts/config';
import {getUserStatistics} from "@/services/customers";
import {getOrderStatistics} from "@/services/orders";
import config from '@/config';
import {saveAdminToken} from "@/services/auth";

export default {
  components: {
    LineChart
  },
  data() {
    return {
      statistics: [],
      bigLineChart: {
        activeIndex: 0,
        chartData: {
          datasets: [{}],
        },
        extraOptions: chartConfigs.purpleChartOptions,
        gradientColors: config.colors.primaryGradient,
        gradientStops: [1, 0.4, 0],
        categories: []
      }
    }
  },
  computed: {
    enableRTL() {
      return this.$route.query.enableRTL;
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    bigLineChartCategories() {
      return ['Usuarios Registrados',
        'Ingresos ($)',
        'Distancia Recorrida'
      ]
    },
  },
  methods: {
    initBigChart(index) {
      let chartData = {
        datasets: [{
          fill: true,
          borderColor: config.colors.primary,
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: config.colors.primary,
          pointBorderColor: 'rgba(255,255,255,0)',
          pointHoverBackgroundColor: config.colors.primary,
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: Object.values(this.statistics[index].data)
        }],
        labels: Object.values(this.statistics[index].month),
      }
      this.$refs.bigChart.updateGradients(chartData);
      this.bigLineChart.chartData = chartData;
      this.bigLineChart.activeIndex = index;

    },

    async getUserStatisticsMethod() {
      return await getUserStatistics().then((res)=>{
        return res.data;
      });
    },

    async getOrderStatisticsMethod() {
      return await getOrderStatistics().then((res)=>{
        return res.data;
      });
    },

    mappedObjectArray(obj){
      const result = []
      Object.keys(obj).forEach(key => {
        for (const [index, value] of Object.entries(obj[key])) {
          if (!result[index]) {
            result[index] = {}
          }
          result[index][key] = value;
        }
      })
      return result;
    },

    requestPermission() {
      const messaging = this.$messaging;
      let KEY_PAIR = `${process.env.VUE_APP_FCM_KEY_PAIR}`;
      console.log("Requesting permission...");
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          messaging.getToken({
            vapidKey: KEY_PAIR
          }).then((currentToken) => {
            if (currentToken) {
              console.log('client token', currentToken);
              this.subscribeTokenToTopic(currentToken, 'fcm_admin');
              let send = {
                tokenId: currentToken,
                userId: this.$store.store.state.userInfo.userId
              }
              saveAdminToken(send).then(res => {
                if (res.data === true) {
                  console.log('Done save or update token')
                }
              });
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          });

          messaging.onMessage((payload) => {
            let notification = payload.notification;

            this.$notify({
              title: notification.title,
              message: notification.body,
              type: "info",
              icon: "tim-icons icon-bell-55",
              timeout: 3000
            });
          });

        } else {
          console.log("Do not have permission!");
        }
      });
    },

    subscribeTokenToTopic(token, topic) {
      let SERVER_KEY = `${process.env.VUE_APP_FCM_SERVER_KEY}`;
      let API = 'https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic;
      fetch(API, {
        method: 'POST',
        headers: new Headers({
          'Authorization': 'key=' + SERVER_KEY
        })
      }).then(response => {
        if (response.status < 200 || response.status >= 400) {
          throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
        }
        console.log('Subscribed to "' + topic + '"');
      }).catch(error => {
        console.error(error);
      });
    }

  },
  async mounted() {
    this.i18n = this.$i18n;
    if (this.enableRTL) {
      this.i18n.locale = 'ar';
      this.$rtl.enableRTL();
    }
    const userStatistics = await this.getUserStatisticsMethod();
    const orderStatistics = await this.getOrderStatisticsMethod();
    this.statistics[0] = this.mappedObjectArray(userStatistics);
    this.statistics[1] = this.mappedObjectArray(orderStatistics);

    this.initBigChart(0);
    this.requestPermission();
  },
  beforeDestroy() {
    if (this.$rtl.isRTL) {
      this.i18n.locale = 'en';
      this.$rtl.disableRTL();
    }
  },

};
</script>
<style>
</style>
