<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Change order status</div>
      </md-card-header>
      <md-card-content>
        <form v-on:submit.prevent="saveStatus(newStatus, user, selectedDate, timeValue)">
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <div>
                <label>Status:</label>
                <md-chip class="text-neutral text-capitalize">{{ this.oldStatus }}</md-chip>
              </div>
              <md-field>
                <label for="status">Change status</label>
                <md-select v-model="newStatus" name="status" id="status" required>
                  <md-option v-for="item in loadStatus" :value="item" :key="item"> {{ item }}</md-option>
                </md-select>
              </md-field>
              <div v-if="newStatus === 'requested' || newStatus === 'scheduled'">
                <md-field>
                  <label for="user">Select broom for new requested</label>
                  <md-select v-model="user" name="user" id="user" required>
                    <md-option v-for="broom in listBroom" :value="broom.userBroomer.id" :key="broom.userBroomer.id">
                      {{ broom.userBroomer.name }} - {{ broom.userBroomer.phone }}
                    </md-option>
                  </md-select>
                </md-field>
                <div v-if="newStatus === 'requested' || newStatus === 'scheduled'">
                  <date-picker v-model="selectedDate" format="YYYY-MM-DD" type="date"
                               placeholder="Select date"
                               :disabled-date="notBeforeToday">
                  </date-picker>
                  <date-picker v-model="timeValue" :minute-step="5" format="HH:mm"
                               value-type="format"
                               type="time" placeholder="Select time" :disabled-time="notBeforeNineOClock">
                  </date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm" role="group" aria-label="">
            <button type="submit" class="btn btn-success">Save changes</button>
            <button class="btn btn-warning" v-on:click="canceled">Canceled</button>
          </div>
        </form>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import {saveLog} from "@/services/logs";
import {getListBroom} from "@/services/broomers";
import {updateOrder, updateStatus} from "@/services/orders";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      loadStatus: ['finished', 'closed', 'requested', 'accepted', 'scheduled', 'started'],
      userId: null,
      listBroom: [],
      user: null,
      oldStatus: null,
      newStatus: null,
      orderData: null,
      selectedDate: null,
      timeValue: null
    }
  },
  methods: {

    getOrderBy() {
      updateOrder(this.$route.params.id).then(res => {
        this.orderData = res.data[0];
        this.oldStatus = this.orderData.status;
        if (this.oldStatus) {
          this.loadStatus = this.loadStatus.filter(data => data !== this.oldStatus);
        }
        if (this.orderData.broomerAssigned == null) {
          this.getBroom(null);
        }
        this.getBroom(this.userId);
      });
    },

    saveStatus(aux, user, data, time) {
      if (aux) {
        let sendData = {
          status: aux,
          id: this.$route.params.id
        }
        updateStatus(sendData).then(res => {
          if (res.data === true) {
            this.saveLogs();
            this.$router.push('/orders');
            this.notify('Update status', 'success');
          } else {
            this.notify('Not update status', 'warning');
          }
        });
      } else {
        this.notify('Please select status', 'warning');
      }
      if (aux && user && data && time) {

        let dateHours = this.dateFormater(data);
        let dateTime = dateHours + ' ' + time + ':00';

        let send = {
          id: this.$route.params.id,
          broomerId: user,
          createdAt: dateTime
        }
        updateStatus(send).then(res => {
          if (res.data === true) {
            this.notify('Update success', 'success');
          } else {
            this.notify("Error update request", 'warning');
          }
        });
      }
    },
    saveLogs() {
      let sendLog = {
        module: this.$route.name,
        key: 'status',
        ipAddress: this.$store.store.state.userInfo.ipClient,
        user: this.$store.store.state.userInfo.userId,
        oldValue: this.oldStatus,
        newValue: this.newStatus,
      }
      saveLog(sendLog).then(res => {
        if (res.data === true) {
          console.log('Done');
        }
      });
    },

    getBroom(id) {
      getListBroom(id).then(res => {
        if (res.data.length > 0) {
          this.listBroom = res.data;
        } else {
          console.log('Not list broom');
        }
      });
    },
    canceled() {
      this.$router.push('/orders');
    },

    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },

    notBeforeNineOClock(date) {
      let today = new Date();
      let time = today.getHours();
      return date.getHours() < time;
    },

    dateFormater(item) {
      // Get year, month, and day part from the date
      let year = item.toLocaleString("default", {year: "numeric"});
      let month = item.toLocaleString("default", {month: "2-digit"});
      let day = item.toLocaleString("default", {day: "2-digit"});

      // Generate yyyy-mm-dd date string
      return year + "-" + month + "-" + day;
    },

    //notifications
    notify(msg, type) {
      this.$notify({
        message: msg,
        type: type,
        icon: "tim-icons icon-bell-55",
        timeout: 2000
      });
    }
  },
  created() {
    this.getOrderBy();
  }
}
</script>
<style>
.mx-time-item {
  color: #73879c;
}
</style>
