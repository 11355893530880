<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">New Promotion</div>
      </md-card-header>
      <md-card-content>
        <form v-on:submit.prevent="createPromotion">
          <md-list class="md-double-line">
            <md-toolbar md-elevation="0">
              <md-subheader class="md-title">Information</md-subheader>
            </md-toolbar>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="name">Description</label>
                  <md-input id="name" type="text" v-model="promotion.description" required/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="email">Type</label>
                  <md-select v-model="promotion.type" name="type" id="type" required>
                    <md-option value="percentage">Percentage (%)</md-option>
                    <md-option value="value">Fixed Value (USD)</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="phone">Value</label>
                  <md-input id="value" type="number" v-model="promotion.value" required/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="maxValue">Max Discount</label>
                  <md-input id="maxValue" type="maxValue" v-model="promotion.maxDiscount"/>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-datepicker id="birthDate" v-model="promotion.validFrom" >
                  <label>Promotion Start Date</label>
                </md-datepicker>
              </div>
              <div class="col-sm">
                <md-datepicker id="birthDate" v-model="promotion.validUntil" >
                  <label>Promotion End Date</label>
                </md-datepicker>
              </div>
            </div>

            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label>Code</label>
                  <md-input id="code" v-model="promotion.code" readonly="" />
                </md-field>
              </div>
              <div class="col-sm">
                <md-checkbox  class="md-primary" v-model="promotion.active">Active</md-checkbox>
              </div>
              <div class="col-sm">
                <md-checkbox  class="md-primary" v-model="promotion.isPublic">Is Public</md-checkbox>
              </div>
            </div>

          </md-list>
          <md-divider></md-divider>
          <div class="col-sm" role="group" aria-label="">
            <button type="submit" class="btn btn-success" v-on:click="editPromotion">Save</button>
            <button class="btn btn-warning" v-on:click="canceled">Canceled</button>
          </div>
        </form>
      </md-card-content>
    </md-card>
    <base-loading :isLoading="isLoading"></base-loading>
  </div>
</template>

<script>
import { getPromotionById, putPromotion } from "@/services/promotion";
import BaseLoading from "@/components/BaseLoading";
import {putBroomer} from "@/services/broomers";

export default {
  data() {
    return {
      promotion:{},
      isLoading: false
    }
  },
  methods: {
    getPromotionbyId() {
      this.isLoading = true;
      getPromotionById(this.$route.params.id).then(res => {
        this.promotion = res.data;
        this.promotion.validFrom = new Date(res.data.validFrom);
        this.promotion.validUntil = new Date(res.data.validUntil);
      }).catch(console.log);
      this.isLoading = false;
    },

    editPromotion() {
      this.isLoading = true;
      let dataSend = {
        id: this.$route.params.id,
        description: this.promotion.description,
        type: this.promotion.type,
        value: this.promotion.value,
        maxDiscount: (this.promotion.maxDiscount) ? this.promotion.maxDiscount : 0,
        startDate:this.promotion.startDate,
        endDate:this.promotion.endDate,
        active:this.promotion.active,
        isPublic:this.promotion.isPublic,
      };

      if (dataSend) {
        putPromotion(dataSend).then(res => {
          if (res.data === true) {
            this.$router.push('/promotion');
          } else {
            console.log('Not Update Promotion');
          }
        });
      }
    },


    canceled() {
      this.$router.push('/promotion');
    },
  },
  mounted() {
    this.getPromotionbyId();
  },
  components: {
    BaseLoading
  }
}
</script>
