<template>
  <md-dialog :md-active="isLoading" class="base-loading" :md-close-on-esc="false" :md-click-outside-to-close="false">
    <md-dialog-title class="text-center">
      <md-progress-spinner md-mode="indeterminate">
      </md-progress-spinner>
    </md-dialog-title>
    <md-dialog-content>
    </md-dialog-content>
  </md-dialog>
</template>
<script>


export default {
  name: "base-loading",
  components: {

  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    }
  },
};
</script>
<style>
.base-loading .md-dialog-container{
  background-color: unset !important;
  box-shadow: unset;
}
</style>
