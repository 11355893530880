import {getHeaders, getHeadersImg, handleLogout} from "@/services/auth";

const axios = require('axios');

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}customer`;
const APIFileUpload = `${process.env.VUE_APP_BROOM_API_ENDPOINT}upload-image`;

export const getListCustomer = async () => {
  try {
    return await axios.get(API, {
      headers: getHeaders()
    });
  } catch (e) {
    console.log(e);
  }
};

export const getCustomerById = async (id) => {
  try {
    const APIGetCustomer = API + '/getById/' + id;
    return await axios.get(APIGetCustomer, {
      headers: getHeaders(),
    });
  } catch (e) {
    console.log(e)
  }
}

export const putCustomer = async (data) => {
  try {
    return await axios.put(API, data,{
      headers: getHeaders()
    });
  } catch (e) {
    console.log(e)
  }
}

export const deleteCustomer = async (id) => {
  try {
    const APIDelete = API + '/' + id;
    return await axios.delete(APIDelete, {
      headers: getHeaders()
    });
  } catch (e) {
    console.log()
  }
}

export const fileUpload = async (data) => {
  try {
    return await axios.post(APIFileUpload, data,{
      headers: getHeadersImg(),
    });
  } catch (e) {
    console.log()
  }
}

export const getUserStatistics = async () => {
  try {
    const APIGetCustomer = API + '/getStatistics';
    return await axios.get(APIGetCustomer, {
      headers: getHeaders(),
    });
  } catch (e) {
    console.log(e)
  }
}

export const getOrderByCustomer = async (id, pageNumber) => {
  try {
    const APIgetOrderByCustomer = API + '/getOrderByCustomer/' + id + '/' + pageNumber;
    return await axios.get(APIgetOrderByCustomer, {
      headers: getHeaders(),
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}
