<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Show Order</div>
      </md-card-header>
      <md-card-content>
        <base-route-maps :dataMaps="orderRoute"></base-route-maps>
        <md-divider></md-divider>
        <md-list class="md-double-line">
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Name User</label>
                <md-input v-model="nameUser" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-list>
                <label>Phone User</label>
                <i class="tim-icons icon-badge">
                  <a :href="'tel:'+phoneUser">&nbsp{{ phoneUser }}</a>
                </i>
              </md-list>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Name Broomer</label>
                <md-input v-model="nameBroomer" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-list>
                <label>Phone Broomer</label>
                <i class="tim-icons icon-badge">
                  <a :href="'tel:'+phoneBroomer">&nbsp{{ phoneBroomer }}</a>
                </i>
              </md-list>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Payment Method</label>
                <md-input v-model="namePaymentMethod" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-field>
                <label>Service</label>
                <md-input v-model="nameService" disabled/>
              </md-field>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Nº Users</label>
                <md-input v-model="orderData.numberOfUsers" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <label>Status: </label>
              <md-chip v-bind:class="getStatus(orderData.status)" disabled
                       class="text-neutral text-capitalize">{{ orderData.status }}
              </md-chip>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Price</label>
                <md-input v-model="orderData.price" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-field>
                <label>Distance</label>
                <md-input v-model="orderData.distance/1000 + ' Km'" disabled>km</md-input>
              </md-field>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Duration</label>
                <md-input v-model="orderData.duration" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-field>
                <label>When</label>
                <md-input v-model="orderData.when" disabled/>
              </md-field>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Start Address</label>
                <md-input v-model="orderData.startAddress" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-field>
                <label>End Address</label>
                <md-input v-model="orderData.endAddress" disabled/>
              </md-field>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Start Date</label>
                <md-input v-model="dateStart" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-field>
                <label>End Date</label>
                <md-input v-model="dateEnd" disabled/>
              </md-field>
            </div>
          </div>
          <div class="form-group row pl-3 pr-3">
            <div class="col-sm">
              <md-field>
                <label>Meeting Point</label>
                <md-input v-model="orderData.meetingPoint" disabled/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-checkbox v-model="orderData.isFree" class="md-primary" disabled>Free</md-checkbox>
            </div>
          </div>
        </md-list>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import {getOrderById} from "@/services/orders";
import BaseRouteMaps from "@/components/BaseRouteMaps";
const decodePolyline = require('decode-google-map-polyline');
export default {
  data() {
    return {
      orderData: [],
      orderRoute: [],
      nameUser: String,
      nameBroomer: String,
      namePaymentMethod: String,
      phoneUser: String,
      phoneBroomer: String,
      nameService: String,
      dateStart: null,
      dateEnd: null,
    }
  },
  methods: {
    getOrderBy() {
      getOrderById(this.$route.params.id).then(res => {
        this.orderData = res.data[0];
        this.nameUser = this.orderData.requiredBy ? this.orderData.requiredBy.name : 'Guest';
        this.phoneUser = this.orderData.requiredBy ? this.orderData.requiredBy.phone : '+0000000000';
        this.nameBroomer = this.orderData.broomerAssigned ? this.orderData.broomerAssigned.name : 'Guest';
        this.phoneBroomer = this.orderData.broomerAssigned ? this.orderData.broomerAssigned.phone : '+0000000000';
        this.namePaymentMethod = this.orderData.paymentMethod ? this.orderData.paymentMethod.name : 'None';
        this.nameService = this.orderData.service ? this.orderData.service.name : 'None';

        this.dateStart = this.formatDate(this.orderData.startDate);
        this.dateEnd = this.formatDate(this.orderData.endDate);

        if (res.data[1]) {
          this.orderRoute = res.data[1].route;
          console.log('Real route');
        } else {
          //I will check if the route is saved in the initial version (complete route) or updated version(encoded polyline)
          //initial version format: [[-0.18021062105863542,-78.50096125155687],[-0.12718488261767893,-78.46612371504307]]
          if(!(this.orderData.route.startsWith("[") &&  this.orderData.route.endsWith("]"))){
            this.orderRoute = decodePolyline(this.orderData.route);
          }
          else{
            this.orderRoute = JSON.parse(this.orderData.route);
          }
          console.log('Google route');
        }

      }).catch(console.log);
    },

    formatDate(date) {
      const options = {year: 'numeric', month: 'long', day: 'numeric'}
      return new Date(date).toLocaleDateString('en', options);
    },
    getStatus(status) {
      switch (status) {
        case 'finished':
          return 'btn-success';
        case 'closed':
          return 'btn-danger';
        case 'requested':
          return 'btn-info';
        default:
          return 'btn-secondary';
      }
    },
  },
  created: function () {
    this.getOrderBy();
  },
  components: {
    BaseRouteMaps
  }
}
</script>
<style>
</style>
