<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Promociones</div>
      </md-card-header>
      <md-card-content>
        <button class="btn btn-success" v-on:click="createdPromotion"> Crear </button>

        <md-table v-model="searched" md-fixed-header>
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable"/>
            </md-field>
          </md-table-toolbar>

          <md-table-empty-state
            md-label="No data found"
            :md-description="`No data found for this '${search}' query. Try a different search term or create a new data.`">
          </md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Description" md-sort-by="description">{{ item.description}}</md-table-cell>
            <md-table-cell md-label="Type" md-sort-by="type" >{{ item.type }}</md-table-cell>
            <md-table-cell md-label="Value" md-sort-by="value" >{{ item.value }}</md-table-cell>
            <md-table-cell md-label="Active">
              <md-checkbox v-model="item.active" readonly="true" disabled></md-checkbox>
            </md-table-cell>
            <md-table-cell md-label="Actions">
                <base-button type="success" size="sm" icon v-on:click="editPromotion(item.id)">
                  <i class="tim-icons icon-settings"></i>
                </base-button>
                <base-button v-if="item.active" type="danger" size="sm" icon v-on:click="deletePromotion(item.id)">
                  <i class="tim-icons icon-simple-remove"></i>
                </base-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions>
        <base-pagination :data="searched" :totalPage="totalPage" @onUpdate="getListCoupons"></base-pagination>
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>

const toLower = text => {
  return text.toString().toLowerCase();
}

const searchByName = (items, term) => {
  if (term) {
    let values = items.filter(x => !!x.userBroomer.name);
    return values.filter(item => toLower(item.userBroomer.name).includes(toLower(term)));
  }
  return items;
}

import BaseTable from "@/components/BaseTable";
import { getListCoupon, disablePromotion } from '@/services/promotion';
import BasePagination from "@/components/BasePagination";

export default {
  components: {BaseTable, BasePagination},
  data() {
    return {
      tableData: [],
      totalPage: null,
      search: null,
      searched: [],
    }
  },
  methods: {
    getListCoupons(pageNumber) {
      getListCoupon(pageNumber).then(res => {
        if (res.data.lists.length > 0) {
          this.tableData = res.data.lists;
          this.totalPage = res.data.totalPages;
          this.searched = this.tableData;
        } else {
          console.log('Not list promotions');
        }
      });
    },
    deletePromotion(id) {
      disablePromotion(id).then(res => {
        if (res.data === true) {
          this.getListCoupons(1);
        } else {
          console.log('Not delete Promotion');
        }
      });
    },
    createdPromotion() {
      this.$router.push('/createPromotion');
    },
    editPromotion(id) {
      this.$router.push('/editPromotion/' + id);
    },
    searchOnTable() {
      this.searched = searchByName(this.tableData, this.search)
    }
  },
  mounted() {
    this.getListCoupons(1);
  }
}
</script>
