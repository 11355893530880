const axios = require('axios');
import {getHeaders} from "@/services/auth";

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}`;

export const getAppErrors = async (pageNumber) => {
  try {
    const APIApperror = API + 'listAppError';
    return await axios.get(APIApperror+'/'+pageNumber, {
      headers: getHeaders(),
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}
