<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Orders</div>
      </md-card-header>
      <md-card-content>
        <BaseOrderTable :tableData="tableData"></BaseOrderTable>
      </md-card-content>
      <md-card-actions>
        <base-pagination :data="tableData" :totalPage="totalPage" @onUpdate="getAllOrders"></base-pagination>
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>
import BaseTable from "@/components/BaseTable";
import {getOrder} from "@/services/orders";
import BasePagination from "@/components/BasePagination";
import BaseOrderTable from "@/components/Orders/BaseOrderTable";

export default {
  components: {BaseTable, BasePagination, BaseOrderTable},
  data() {
    return {
      tableData: [],
      totalPage: null
    }
  },
  methods: {
    getAllOrders(pageNumber) {
      getOrder(pageNumber).then(res => {
        if (res.data.orders.length > 0) {
          this.tableData = res.data.orders;
          this.totalPage = res.data.totalPages;
        } else {
          console.log('Not list orders');
        }
      });
    },
  },
  beforeMount() {
    this.getAllOrders(1);
  }
}
</script>
<style>
</style>
