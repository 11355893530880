<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Customers</div>
      </md-card-header>
      <md-card-content>

        <md-table v-model="searched" md-fixed-header>
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable"/>
            </md-field>
          </md-table-toolbar>

          <md-table-empty-state
            md-label="No users found"
            :md-description="`No user found for this '${search}' query. Try a different search term or create a new user.`">
          </md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Name" md-sort-by="name">{{ item.name }}</md-table-cell>
            <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
            <md-table-cell md-label="Phone" md-sort-by="phone">{{ item.phone }}</md-table-cell>
            <md-table-cell md-label="Active">
              <md-checkbox v-model="item.active" class="md-primary" readonly="true" disabled></md-checkbox>
            </md-table-cell>
            <md-table-cell md-label="Actions">
              <base-button type="info" size="sm" icon v-on:click="orderCustomer(item.id)">
                <i class="tim-icons icon-cart"></i>
              </base-button>
              <base-button type="success" size="sm" icon v-on:click="editCustomer(item.id)">
                <i class="tim-icons icon-settings"></i>
              </base-button>
              <base-button type="danger" size="sm" icon v-on:click="deleteCustomer(item.id)">
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {

    let values = items.filter(x => !!x.name);

    return values.filter(item => toLower(item.name).includes(toLower(term)))
  }

  return items
}

import BaseTable from "@/components/BaseTable";
import {getListCustomer, deleteCustomer} from '@/services/customers';

export default {
  components: {BaseTable},
  data() {
    return {
      tableData: [],
      search: null,
      searched: [],
    }
  },
  methods: {
    getAllCustomers() {
      getListCustomer().then(res => {
        if (res.data.length > 0) {
          this.tableData = res.data;
          this.searched = this.tableData;
        } else {
          console.log('Not list Customer');
        }
      });
    },
    deleteCustomer(id) {
      deleteCustomer(id).then(res => {
        if (res.data === true) {
          this.getAllCustomers();
        } else {
          console.log('Not delete broomer');
        }
      });
    },
    editCustomer(id) {
      this.$router.push('/editCustomer/' + id);
    },

    searchOnTable() {
      this.searched = searchByName(this.tableData, this.search)
    },

    orderCustomer(id) {
      this.$router.push('/orderCustomer/' + id);
    },

  },
  mounted() {
    this.getAllCustomers();
  }
}
</script>
