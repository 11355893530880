<template>
  <div class="login-container">
    <card class="col-12 col-md-8 mt-5">
      <h2 class="control-label">Login</h2>
      <form @submit="handleLogin">
        <div class="row">
          <div class="col-md-12">
            <md-field>
              <label for="email">Correo</label>
              <md-input type="email" name="email" id="email" autocomplete="email" v-model="email" required />
            </md-field>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <md-field>
              <label for="password">Contraseña</label>
              <md-input type="password" name="password" id="password" v-model="password" required />
            </md-field>
          </div>
        </div>
        <div class="row">
          <div class="col-7">
            <md-button to="recuperar-contraseña" class="md-primary">Recuperar Contraseña</md-button>
          </div>
          <div class="col-12 col-md-5 submit-container">
            <md-button class="md-raised md-primary" type="submit" :disabled="isLoading">Aceptar</md-button>
          </div>
        </div>
      </form>
      <md-snackbar md-position="center" :md-duration="3000" :md-active.sync="showSnackMessage" md-persistent>
        <span>{{snackMessage}}</span>
      </md-snackbar>
    </card>
  </div>
</template>
<script>
import {login} from '@/services/auth';
const md5 = require('md5');

export default {
  components: {
  },
  data() {
    return {
      email: "",
      password: "",
      showSnackMessage: false,
      snackMessage: null,
      isLoading: false
    }
  },
  beforeCreate() {
  },
  created() {

  },
  methods: {

    handleLogin(e) {
      e.preventDefault();
      this.isLoading = true;
      login({email:this.email,password:md5(this.password)}).then(valid=>{
        if (valid) {
          this.$router.push('/');
        }
        else {
          this.snackMessage = 'No pudimos iniciar sesión. Por favor vuelve a intentarlo';
          this.showSnackMessage = true;
          this.isLoading = false;
        }
      });

    },
  }
}
</script>
<style>
.submit-container{
  text-align: right;
}
</style>
