<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">New Broomer</div>
      </md-card-header>
      <md-card-content>
        <form v-on:submit.prevent="createBroomer">
          <md-list class="md-double-line">
            <md-toolbar md-elevation="0">
              <md-subheader class="md-title">Basic Information</md-subheader>
            </md-toolbar>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="name">Name</label>
                  <md-input id="name" type="text" v-model="broomer.name" required/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="email">Email</label>
                  <md-input id="email" type="email" v-model="broomer.email" required/>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="phone">Phone</label>
                  <md-input id="phone" type="text" v-model="broomer.phone" required/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="password">Password</label>
                  <md-input id="password" type="password" v-model="broomer.password"/>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="cedula">Cedula</label>
                  <md-input id="cedula" type="text" v-model="broomer.cedula" required/>
                </md-field>
              </div>
            </div>
          </md-list>
          <md-divider></md-divider>
          <md-list class="md-double-line">
            <md-toolbar md-elevation="0">
              <md-subheader class="md-title">Broom Information</md-subheader>
            </md-toolbar>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-autocomplete v-model="broomer.transportType" required :md-options="transports">
                  <label for="transportType">Select Type</label>
                </md-autocomplete>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="cardRate">Card Rate</label>
                  <md-input type="number" id="cardRate" v-model="broomer.cardRate" maxlength="2"/>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="cardDrive">Card Drive</label>
                  <md-file v-model="broomer.cardDrive" accept="image/*" id="cardDrive" @change="onFileSelected"
                           required></md-file>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="cardRegistration">Card Registration</label>
                  <md-file v-model="broomer.cardRegistration" accept="image/*" id="cardRegistration"
                           @change="onFileSelected2" required></md-file>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="cabNumber">Cab Number</label>
                  <md-input id="cabNumber" v-model="broomer.plate"/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-checkbox v-model="broomer.isValidate" class="md-primary">Validate</md-checkbox>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="brand">Brand</label>
                  <md-input id="brand" type="text" v-model="broomer.brand"/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="year">Year</label>
                  <md-input id="year" type="text" v-model="broomer.year"/>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="description">Description</label>
                  <md-input id="description" type="text" v-model="broomer.description"/>
                </md-field>
              </div>
              <div class="col-sm">
                <md-field>
                  <label for="color">Color</label>
                  <md-input id="color" type="text" v-model="broomer.color"/>
                </md-field>
              </div>
            </div>
            <div class="form-group row pl-3 pr-3">
              <div class="col-sm">
                <md-field>
                  <label for="comments">Comments</label>
                  <md-textarea class="form-control" id="comments" v-model="broomer.comments"></md-textarea>
                </md-field>
              </div>
            </div>
          </md-list>
          <div class="col-sm" role="group" aria-label="">
            <button type="submit" class="btn btn-success">Add</button>
            <button class="btn btn-warning" v-on:click="canceled">Canceled</button>
          </div>
        </form>
      </md-card-content>
    </md-card>
    <base-loading :isLoading="isLoading"></base-loading>
  </div>
</template>

<script>
import {getUserByRol, postBroomer, fileUpload} from "@/services/broomers";
import BaseLoading from "@/components/BaseLoading";

export default {
  data() {
    return {
      broomer: {},
      user: [],
      selectedFile: null,
      selectedFile2: null,
      transports: [
        'Car', 'Motorcycle'
      ],
      isLoading: false
    }
  },
  methods: {
    createBroomer() {
      this.isLoading = true;
      let dataSend = {
        name: this.broomer.name,
        phone: this.broomer.phone,
        email: this.broomer.email,
        password: this.broomer.password,
        transportType: this.broomer.transportType,
        cardDrive: this.broomer.cardDrive,
        cardRegistration: this.broomer.cardRegistration,
        cardRate: this.broomer.cardRate,
        plate: this.broomer.plate,
        isValidate: this.broomer.isValidate,
        comments: this.broomer.comments,
        brand: this.broomer.brand,
        year: this.broomer.year,
        description: this.broomer.description,
        color: this.broomer.color,
        cedula: this.broomer.cedula,
      };

      if (dataSend) {
        postBroomer(dataSend).then(res => {
          if (res.data === true) {
            this.$router.push('/broomer');
          } else {
            console.log('Not Create Broomer');
          }
          this.isLoading = false;
        });
      }
    },
    getAllUsers: function () {
      getUserByRol().then(res => {
        this.user = [];
        if (typeof res !== 'undefined') {
          this.user = res.data;
        } else {
          console.log('Not data')
        }
      });
    },
    onFileSelected(event) {
      this.isLoading = true;
      this.selectedFile = event.target.files[0];
      this.onUpload();
    },
    onFileSelected2(event) {
      this.isLoading = true;
      this.selectedFile2 = event.target.files[0];
      this.onUpload2();
    },
    onUpload() {
      const fd = new FormData()
      fd.append('file', this.selectedFile, this.selectedFile.name);
      fileUpload(fd).then(res => {
        if (typeof res.data) {
          this.broomer.cardDrive = res.data;
        } else {
          console.log('Error');
        }
        this.isLoading = false;
      });

    },
    onUpload2() {
      const fd = new FormData()
      fd.append('file', this.selectedFile2, this.selectedFile2.name);
      fileUpload(fd).then(res => {
        if (typeof res.data) {
          this.broomer.cardRegistration = res.data;
        } else {
          console.log('Error');
        }
        this.isLoading = false;
      });
    },
    clearForm() {

    },
    canceled() {
      this.$router.push('/broomer');
    }

  },
  created: function () {
    this.getAllUsers();
  },
  components: {
    BaseLoading
  }
}
</script>
