import {getHeaders, handleLogout} from "@/services/auth";

const axios = require('axios');

const API = `${process.env.VUE_APP_BROOM_API_ENDPOINT}orders`;

export const getOrder = async (pageNumber) => {
  try {
    return await axios.get(API + '/' + pageNumber, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const getOrderById = async (id) => {
  try {
    const APIGetById = API + '/getOrderById/' + id;
    return await axios.get(APIGetById, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}

export const getOrderStatistics = async () => {
  try {
    const APIGetOrder = `${process.env.VUE_APP_BROOM_API_ENDPOINT}orderStatistics`;
    return await axios.get(APIGetOrder, {
      headers: getHeaders(),
    });
  } catch (e) {
    console.log(e)
  }
}

export const updateStatus = async (data) => {
  try {
    const APIUpdateStatus = API + '/updateStatus';
    return await axios.put(APIUpdateStatus, data, {
      headers: getHeaders()
    });
  } catch (e) {
  }
}

export const updateOrder = async (id) => {
  try {
    const APIGetById = API + '/updateOrder/' + id;
    return await axios.get(APIGetById, {
      headers: getHeaders()
    });
  } catch (e) {
    if (e.response && e.response.status === 403) {
      handleLogout();
    }
  }
}
