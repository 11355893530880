<template>
  <div>
    <div id="map" class="map"></div>
    <base-loading :isLoading="isLoading"></base-loading>
  </div>
</template>
<script>
import BaseLoading from "@/components/BaseLoading";
export default {
  name: "base-route-maps",
  props: {
    dataMaps: {
      type: Array,
      default: null,
      description: "Data"
    },
  },
  methods: {
    drawMap: function () {
      let routeData = []

      this.dataMaps.forEach(function (item) {
        let route = {};
        if (item.lat && item.lng) {
          route.lat = item.lat;
          route.lng = item.lng;
        } else {
          route.lat = item[0];
          route.lng = item[1];
        }
        routeData.push(route);
      });

      const start = routeData[0];
      const end = routeData[routeData.length - 1];

      const map = new google.maps.Map(document.getElementById('map'),
        {
          zoom: 12,
          center: start,
          mapTypeId: 'roadmap',
        }
      );

      new google.maps.Marker({
        position: start,
        map,
        label: 'A'
      });

      new google.maps.Marker({
        position: end,
        map,
        label: 'B'
      });

      const path = new google.maps.Polyline({
        path: routeData,
        geodesic: true,
        strokeColor: '#33d1ff',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });

      path.setMap(map);
      this.isLoading = false;
    },
  },
  mounted() {
    this.isLoading = true;
    setTimeout(() => {
      this.drawMap();
    }, 2000);
  },
  components: {
    BaseLoading
  },
  data() {
    return {
      isLoading: false
    }
  },
}
</script>
