<template>
  <div>
    <md-card>
      <md-card-header>
        <div class="md-title">Broomers</div>
      </md-card-header>
      <md-card-content>
        <button class="btn btn-success" v-on:click="createdBroomer">Create</button>

        <md-table v-model="searched" md-fixed-header>
          <md-table-toolbar>
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable"/>
            </md-field>
          </md-table-toolbar>

          <md-table-empty-state
            md-label="No data found"
            :md-description="`No data found for this '${search}' query. Try a different search term or create a new data.`">
          </md-table-empty-state>

          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Name" md-sort-by="name">{{ item.userBroomer.name }}</md-table-cell>
            <md-table-cell md-label="Email" md-sort-by="email">{{ item.userBroomer.email }}</md-table-cell>
            <md-table-cell md-label="Rate" md-sort-by="rate">{{ item.userBroomer.rate }}</md-table-cell>
            <md-table-cell md-label="Phone" md-sort-by="phone">{{ item.userBroomer.phone }}</md-table-cell>
            <md-table-cell md-label="Type" md-sort-by="type">{{ item.transportType }}</md-table-cell>
            <md-table-cell md-label="Card Rate" md-sort-by="cardRate">{{ item.cardRate }}</md-table-cell>
            <md-table-cell md-label="Cab Number" md-sort-by="cabNumber">{{ item.plate }}</md-table-cell>
            <md-table-cell md-label="Validate">
              <md-checkbox v-model="item.isValidate" readonly="true" disabled></md-checkbox>
            </md-table-cell>
            <md-table-cell md-label="Active">
              <md-checkbox v-model="item.active" readonly="true" disabled></md-checkbox>
            </md-table-cell>
            <md-table-cell md-label="Actions">
              <base-button type="info" size="sm" icon v-on:click="orderBroomer(item.userBroomer.id)">
                <i class="tim-icons icon-cart"></i>
              </base-button>
              <base-button type="success" size="sm" icon v-on:click="editBroomer(item.id)">
                <i class="tim-icons icon-settings"></i>
              </base-button>
              <base-button type="danger" size="sm" icon v-on:click="deleteBroomer(item.id)">
                <i class="tim-icons icon-simple-remove"></i>
              </base-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>
      <md-card-actions>
        <base-pagination :data="searched" :totalPage="totalPage" @onUpdate="getAllBroomers"></base-pagination>
      </md-card-actions>
    </md-card>
  </div>
</template>
<script>

const toLower = text => {
  return text.toString().toLowerCase();
}

const searchByName = (items, term) => {
  if (term) {

    let values = items.filter(x => !!x.userBroomer.name);

    return values.filter(item => toLower(item.userBroomer.name).includes(toLower(term)));
  }

  return items;
}

import BaseTable from "@/components/BaseTable";
import {getListBroomer, deleteBroomer} from '@/services/broomers';
import BasePagination from "@/components/BasePagination";

export default {
  components: {BaseTable, BasePagination},
  data() {
    return {
      tableData: [],
      totalPage: null,
      search: null,
      searched: [],
    }
  },
  methods: {
    getAllBroomers(pageNumber) {
      getListBroomer(pageNumber).then(res => {
        if (res.data.lists.length > 0) {
          this.tableData = res.data.lists;
          this.totalPage = res.data.totalPages;
          this.searched = this.tableData;
        } else {
          console.log('Not list broomers');
        }
      });
    },
    deleteBroomer(id) {
      deleteBroomer(id).then(res => {
        if (res.data === true) {
          this.getAllBroomers(1);
        } else {
          console.log('Not delete broomer');
        }
      });
    },
    createdBroomer() {
      this.$router.push('/createBroomer');
    },
    editBroomer(id) {
      this.$router.push('/editBroomer/' + id);
    },
    orderBroomer(id) {
      this.$router.push('/orderBroomer/' + id);
    },
    searchOnTable() {
      this.searched = searchByName(this.tableData, this.search)
    }
  },
  mounted() {
    this.getAllBroomers(1);
  }
}
</script>
