<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        Edit Customer
      </div>
      <div class="card-body">
        <form v-on:submit.prevent="editCustomer">
          <div class="form-group row">
            <div class="col-6">
              <label for="cardDrive">Avatar</label>
              <md-image class="col-6">
                <img :src="customer.image" alt="" height="100%" width="100%">
              </md-image>
              <md-field>
                <md-file v-model="customer.image" accept="image/*" id="avatar" @change="onFileSelected"></md-file>
              </md-field>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm">
              <md-field>
                <label for="firstName">Name</label>
                <md-input id="firstName" v-model="customer.name"/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-field>
                <label for="email">Email</label>
                <md-input id="email" v-model="customer.email"/>
              </md-field>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm">
              <md-field>
                <label for="cardRate">Phone Number</label>
                <md-input id="phoneNumber" v-model="customer.phone"  aria-required="true" readonly="" disabled=""/>
              </md-field>
            </div>
            <div class="col-sm">
              <md-field>
                <label for="cabNumber">rate</label>
                <md-input id="rate" readonly="" disabled="" />
              </md-field>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm">
                <md-datepicker id="birthDate" v-model="customer.birthdate" >
                  <label>Birth Date</label>
                </md-datepicker>
            </div>
            <div class="col-sm">
              <md-checkbox  class="md-primary" v-model="customer.active">Active</md-checkbox>
            </div>
          </div>
          <div class="col-sm" role="group" aria-label="">
            <button type="submit" class="btn btn-success">Edit</button>
            <button class="btn btn-warning" v-on:click="canceledCustomer">Canceled</button>
          </div>
        </form>
      </div>
    </div>
    <base-loading :isLoading="isLoading"></base-loading>
  </div>
</template>
<script>
import { getCustomerById, putCustomer, fileUpload } from "@/services/customers";
import BaseLoading from "@/components/BaseLoading";

export default {
  data() {
    return {
      customer:{},
      user: [],
      selectedFile: null,
      isLoading: false
    }
  },
  methods: {
    getCustomerbyId() {
      getCustomerById(this.$route.params.id).then(res => {
        this.customer = res.data;
        this.customer.birthdate = new Date(res.data.birthdate);
      }).catch(console.log);
    },
    editCustomer() {
      this.isLoading = true;

      let dataSend = {
        id: this.$route.params.id,
        name: this.customer.name,
        email: this.customer.email,
        birthdate: this.customer.birthdate,
        active: this.customer.active,
        image:this.customer.image
      };

      if (dataSend) {
        putCustomer(dataSend).then(res => {
          if (res.data === true) {
            this.$router.push('/customers');
          } else {
            console.log('Not Update Customer');
          }
        });
      }
    },
    onFileSelected(event) {
      this.isLoading = true;
      this.selectedFile = event.target.files[0];
      this.onUpload();
    },
    onUpload() {
      const fd = new FormData()
      fd.append('file', this.selectedFile, this.selectedFile.name);
      fileUpload(fd).then(res => {
        if (typeof res.data) {
          setTimeout(()=> {
            this.isLoading = false;
            this.customer.image = res.data;
          }, 1000 )
        } else {
          this.isLoading = false;
          console.log('Error');
        }

      });
    },
    canceledCustomer() {
      this.$router.push('/customers');
    }
  },
  created: function () {
    this.getCustomerbyId();
  },
  components: {
    BaseLoading
  }
}
</script>
